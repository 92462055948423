import { useContext } from "react"
import { HomeLogo } from "../components/HomeLogo"
import { HomeNavbar } from "../components/HomeNavbar"
import { BytSyzContext } from "../context/BytSyzContext"

export const HomePage = () => {
    const pages = [
        {
            link: "/About_Us",
            name: "About Us"
        },
        {
            link: "/Products_and_Services",
            name: "Products and Services"
        },
        {
            link: "/Contact_Us",
            name: "Contact Us"
        }
    ]
    const { dark } = useContext(BytSyzContext)
    return (
        <div style={dark ? { backgroundColor: "#000000", minHeight: "100vh" } : { backgroundColor: "#FFFFFF", minHeight: "100vh" }}>
            <HomeLogo />
            <HomeNavbar pages={pages} />
        </div >
    )
}