import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom"
import { BytSyzContext } from "../context/BytSyzContext";
import { DarkLightModeToggle } from "./DarkLightModeToggle";
import MenuIcon from '@mui/icons-material/Menu';
import './componentStyles/navbarHover.css'
import { NavbarDrawer } from "./NavbarDrawer";

export const Navbar = ({ pages }) => {

    const { dark, windowNarrow } = useContext(BytSyzContext)
    const [openDrawer, setOpenDrawer] = useState(false)

    if (windowNarrow) {
        return (
            <AppBar position="static" style={dark ? { boxShadow: "rgb(255 255 255 / 100%) 0px 1px 2px 1px, rgb(225 225 225/ 50%) 0px 3px 5px 0px, rgb(200 200 200 / 30%) 0px 0px 10px 2px" } : { boxShadow: "rgb(0 0 0 / 30%) 0px 3px 4px -1px, rgb(0 0 0/ 15%) 0px 5px 5px 0px, rgb(0 0 0 / 12%) 0px 0px 10px 0px" }}>
                <Toolbar style={dark ? { backgroundColor: "#000000" } : { backgroundColor: "#FFFFFF" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", textAlign: "center" }}>
                        <Button onClick={() => { setOpenDrawer(true) }}>
                            <MenuIcon style={dark ? { color: "#FFFFFF" } : { color: "#000000" }} />
                        </Button>
                        <NavLink to={"/"} style={{ textDecoration: "none" }}>
                            <Typography variant="h3" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                                BytSyz
                            </Typography>
                        </NavLink>
                        <DarkLightModeToggle />
                    </div>
                    {openDrawer && <NavbarDrawer setOpen={setOpenDrawer} pages={pages} />}
                </Toolbar>
            </AppBar>
        )
    } else {
        return (
            <AppBar position="static" style={dark ? { boxShadow: "rgb(255 255 255 / 100%) 0px 1px 2px 1px, rgb(225 225 225/ 50%) 0px 3px 5px 0px, rgb(200 200 200 / 30%) 0px 0px 10px 2px" } : { boxShadow: "rgb(0 0 0 / 30%) 0px 3px 4px -1px, rgb(0 0 0/ 15%) 0px 5px 5px 0px, rgb(0 0 0 / 12%) 0px 0px 10px 0px" }}>
                <Toolbar style={dark ? { backgroundColor: "#000000" } : { backgroundColor: "#FFFFFF" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center", textAlign: "center" }}>
                        <NavLink to={"/"} style={{ textDecoration: "none" }}>
                            <Typography variant="h3" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                                BytSyz
                            </Typography>
                        </NavLink>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                {pages && pages.map((page, i) => {
                                    return (
                                        <NavLink key={page.name} to={page.link} style={{ textDecoration: "none" }}>
                                            <Typography id="navlink" paddingLeft={5} paddingRight={5} color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} variant="h6" style={{ textTransform: "uppercase", width: "max-content" }} >
                                                {page.name}
                                            </Typography>
                                        </NavLink>
                                    )
                                })}
                            </div>
                            <DarkLightModeToggle />
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        )
    }

}