import { Typography } from '@mui/material';
import { useContext } from 'react';
import { BytSyzContext } from '../../../context/BytSyzContext';

export const LinksToOtherSites = () => {

    const { dark } = useContext(BytSyzContext)

    return (
        <>
            <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                Links to Other Sites
            </Typography>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
            </Typography>
        </>
    )

}