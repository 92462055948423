import { Button, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { BytSyzContext } from "../context/BytSyzContext";

export const ContactForm = () => {
    const { dark } = useContext(BytSyzContext)

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [number, setNumber] = useState()
    const [enquiry, setEnquiry] = useState()

    const [submitted, setSubmitted] = useState(false)

    const googleFormLink = "https://docs.google.com/forms/d/1mjED1_qpGJB19kz4_Pr7RVguTIWYAvnsjFNfkRBdYeM/formResponse"

    const submit = () => {
        if ((name === undefined) || (email === undefined) || (number === undefined) || (enquiry === undefined)) {
            alert("Some fields in the form are not filled in. Please fill each field.")
        } else if ((name === "") || (email === "") || (number === "") || (enquiry === "")) {
            alert("Some fields in the form are not filled in. Please fill each field.")
        } else {
            // Construct x-www-form-urlencoded payload
            let formDetails = {
                'entry.1513066496': name,
                'entry.1009580475': email,
                'entry.766993423': number,
                'entry.700831522': enquiry
            };
            let formBody = [];
            for (let field in formDetails) {
                let encodedKey = encodeURIComponent(field);
                let encodedValue = encodeURIComponent(formDetails[field]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            // Payload created
            fetch(googleFormLink, {
                method: 'POST',
                mode: 'no-cors', // no-cors, *cors, same-origin
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                redirect: 'follow', // manual, *follow, errorreferrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: formBody // body data type must match "Content-Type" header
            }).then(() => {
                setSubmitted(true)
            })
        }
    }

    return (
        <>
            {!submitted &&
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        type={"text"}
                        placeholder="Name"
                        required
                        onChange={(event) => {
                            setName(event.target.value)
                        }}
                        style={dark ? { backgroundColor: "#F0F0F0", borderRadius: 5, marginTop: 10, marginBottom: 10, width: "80%", alignSelf: "center" } : { borderRadius: 5, marginTop: 10, marginBottom: 10, width: "80%", alignSelf: "center" }}
                    />

                    <TextField
                        type={"email"}
                        placeholder="Email"
                        required
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }}
                        style={dark ? { backgroundColor: "#F0F0F0", borderRadius: 5, marginTop: 10, marginBottom: 10, width: "80%", alignSelf: "center" } : { borderRadius: 5, marginTop: 10, marginBottom: 10, width: "80%", alignSelf: "center" }}
                    />

                    <TextField
                        type={"text"}
                        placeholder="Phone number"
                        required
                        onChange={(event) => {
                            setNumber(event.target.value)
                        }}
                        style={dark ? { backgroundColor: "#F0F0F0", borderRadius: 5, marginTop: 10, marginBottom: 10, width: "80%", alignSelf: "center" } : { borderRadius: 5, marginTop: 10, marginBottom: 10, width: "80%", alignSelf: "center" }}
                    />

                    <TextField
                        type={"text"}
                        placeholder="Enquiry"
                        required
                        multiline
                        rows={3}
                        onChange={(event) => {
                            setEnquiry(event.target.value)
                        }}
                        style={dark ? { backgroundColor: "#F0F0F0", borderRadius: 5, marginTop: 10, marginBottom: 10, width: "80%", alignSelf: "center" } : { borderRadius: 5, marginTop: 10, marginBottom: 10, width: "80%", alignSelf: "center" }}
                    />

                    <Button onClick={() => { submit() }}>
                        <Typography variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                            Submit
                        </Typography>
                    </Button>
                </div>
            }
            {
                submitted &&
                <>
                    <Typography variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                        Thank you for submitting your enquiry!
                    </Typography>
                </>
            }
        </>
    );
}