import { Typography } from '@mui/material';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar } from '../components/Navbar';
import { BytSyzContext } from '../context/BytSyzContext';
import './pageStyles/navInTextHover.css'

export const AboutUs = () => {

    const { dark } = useContext(BytSyzContext)

    const pages = [
        {
            link: "/",
            name: "Home"
        },
        {
            link: "/Products_and_Services",
            name: "Products and Services"
        },
        {
            link: "/Contact_Us",
            name: "Contact Us"
        }
    ]

    return (
        <div style={dark ? { backgroundColor: "#000000", minHeight: "100vh", textAlign: "center" } : { backgroundColor: "#FFFFFF", minHeight: "100vh", textAlign: "center" }}>
            <Navbar pages={pages} />
            <div style={{ padding: 40 }}>
                <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h3" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                    About Us
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    BytSyz Limited was found in 2021.
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    We are a growing team in the technological world, bringing software solutions to you.
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    As a software and technological solutions company, we offer a line of products along with consultancy services. Some of our developed applications are available in the PlayStore and are shown in our <NavLink id="navtext" to={"/Products_and_Services"} style={dark ? { color: "#FFFFFF" } : { color: "#000000" }}>"Products and Services"</NavLink> page as well.
                </Typography>
            </div>
        </div>
    );
}