import { Typography } from "@mui/material"
import { useContext } from "react"
import { BytSyzContext } from "../context/BytSyzContext"

export const HomeLogo = () => {

    const { dark } = useContext(BytSyzContext)

    return (
        <div style={{ paddingTop: 100, paddingBottom: 100, height: "60vmin", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography variant="h1" fontSize={"20vmin"} color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"} textAlign={"center"}>
                BytSyz
            </Typography>
        </div>
    )
}