import { Typography } from '@mui/material';
import { useContext } from 'react';
import { BytSyzContext } from '../../../context/BytSyzContext';

export const Security = () => {

    const { dark } = useContext(BytSyzContext)

    return (
        <>
            <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                Security
            </Typography>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage, is 100% secure and reliable, and we cannot guarantee its absolute security.
            </Typography>
        </>
    )

}