import { useContext } from "react"
import { BytSyzContext } from "../context/BytSyzContext"
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Button } from "@mui/material";

export const DarkLightModeToggle = () => {
    const { dark, setDark } = useContext(BytSyzContext)
    return (
        <Button onClick={() => { setDark(!dark) }}>
            {dark && <DarkModeIcon style={{ color: "#FFFFFF" }} />}
            {!dark && <LightModeIcon style={{ color: "#000000" }} />}
        </Button>
    )
}