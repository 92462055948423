import { Typography } from '@mui/material';
import { useContext } from 'react';
import { BytSyzedApps } from '../components/BytSyzedApps';
import { Navbar } from '../components/Navbar';
import { BytSyzContext } from '../context/BytSyzContext';

export const ProductsServices = () => {
    const { dark } = useContext(BytSyzContext)
    const pages = [
        {
            link: "/",
            name: "Home"
        },
        {
            link: "/About_Us",
            name: "About Us"
        },
        {
            link: "/Contact_Us",
            name: "Contact Us"
        }
    ]
    return (

        <div style={dark ? { backgroundColor: "#000000", minHeight: "100vh", textAlign: "center" } : { backgroundColor: "#FFFFFF", minHeight: "100vh", textAlign: "center" }}>
            <Navbar pages={pages} />
            <div style={{ padding: 40 }}>
                <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h3" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                    Products And Services
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h4" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                    An Overview
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    BytSyz Limited develops a number of internal products, and once a production level product is ready, it is published (some of which are detailed below).
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    In addition to having a number of our projects in the development pipeline, we offer consultancy services around software development and take on projects from clients.
                </Typography>
                <Typography style={{ paddingTop: 40, paddingBottom: 40 }} variant="h4" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                    "BytSyz"-ed Apps
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    Some of our small, "BytSyz"-ed applications have been released on the PlayStore.
                </Typography>
                <BytSyzedApps />
            </div>
        </div>
    );
}