import { Typography } from '@mui/material';
import { useContext } from 'react';
import { ContactForm } from '../components/ContactForm';
import { Navbar } from '../components/Navbar';
import { BytSyzContext } from '../context/BytSyzContext';

export const ContactUs = () => {
    const { dark } = useContext(BytSyzContext)
    const pages = [
        {
            link: "/",
            name: "Home"
        },
        {
            link: "/About_Us",
            name: "About Us"
        },
        {
            link: "/Products_and_Services",
            name: "Products and Services"
        }
    ]
    return (
        <div style={dark ? { backgroundColor: "#000000", minHeight: "100vh", textAlign: "center" } : { backgroundColor: "#FFFFFF", minHeight: "100vh", textAlign: "center" }}>
            <Navbar pages={pages} />
            <div style={{ padding: 40 }}>
                <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h3" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                    Contact Us
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    Contact us for any requests, enquiries or appointments. We will try to get back to you as soon as possible.
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 60 }} variant="h6" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    Acceptance of any appointments/work is subject to our resources and capacity.
                </Typography>
                <ContactForm />
            </div>
        </div>
    );
}