import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import './componentStyles/navbarHover.css'
import deadlineLogo from './appImages/Deadline_Logo.png'
import quicknoteLogo from './appImages/QuickNote_Logo.png'
import { useContext } from "react";
import { BytSyzContext } from "../context/BytSyzContext";

export const BytSyzedApps = () => {
    const { dark, windowNarrow } = useContext(BytSyzContext)
    const apps = [
        {
            name: "Deadline",
            image: deadlineLogo,
            description: "Deadline is a lightweight and minimalistic task and deadline tracker. It requires no sign ups, just open the app and start. An easy way to keep track of your deadlines on the go.",
            playstoreLink: "https://play.google.com/store/apps/details?id=dev.BytSyz.Deadline"
        },
        {
            name: "QuickNote",
            image: quicknoteLogo,
            description: "QuickNote is a note taking application. Its features include adding, editing, and clearing a note; light/dark mode; and notifications reminders.",
            playstoreLink: "https://play.google.com/store/apps/details?id=dev.BytSyz.QuickNote"
        }
    ]
    return (
        <Grid container spacing={4} style={{ justifyContent: "center" }}>
            {
                apps && apps.map((app, index) =>
                    <Grid item xs={10} key={index}>
                        <a id="externalNav" href={app.playstoreLink} target="_blank" style={dark ? { color: "#FFFFFF" } : { color: "#000000" }} rel="noopener noreferrer">
                            <Card sx={{ display: 'flex', borderRadius: "2em", borderWidth: "medium" }} variant="outlined" style={dark ? { backgroundColor: "#181818" } : { backgroundColor: "#FFFFFF" }}>
                                {!windowNarrow &&
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 250 }}
                                        image={app.image}
                                        alt={app.name}
                                    />
                                }
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography variant={"h4"} color={dark ? "#FFFFFF" : "#000000"} paddingBottom={3} fontWeight={"bold"} fontFamily={"Courier New, Courier, monospace"}>
                                            {app.name}
                                        </Typography>
                                        <Typography variant={"h6"} color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                                            {app.description}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Card>
                        </a>
                    </Grid>
                )
            }
        </Grid>
    )
}