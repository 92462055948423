import { Navigate, Route, Routes, useLocation } from 'react-router';
import { AboutUs } from './pages/AboutUs';
import { ProductsServices } from './pages/ProductsServices';
import { ContactUs } from './pages/ContactUs';
import { HomePage } from './pages/Home';
import { QuickNotePrivacyPolicy } from './pages/privacyPolicy/QuickNotePrivacyPolicy';
import { DeadlinePrivacyPolicy } from './pages/privacyPolicy/DeadlinePrivacyPolicy';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './App.css'

export const App = () => {

  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="pageChange" timeout={300}>
        <Routes >
          <Route path="/" element={<HomePage />} />
          <Route path="About_Us" element={<AboutUs />} />
          <Route path="Products_and_Services" element={<ProductsServices />} />
          <Route path="Contact_Us" element={<ContactUs />} />
          <Route path="Privacy_Policy/Deadline" element={<DeadlinePrivacyPolicy />} />
          <Route path="Privacy_Policy/QuickNote" element={<QuickNotePrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" />} />
        </ Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}