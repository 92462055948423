import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Button, SwipeableDrawer, Toolbar, Typography } from '@mui/material';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { BytSyzContext } from '../context/BytSyzContext';
import './componentStyles/navbarHover.css'

export const NavbarDrawer = ({ setOpen, pages }) => {
    const { dark } = useContext(BytSyzContext)
    return (
        <SwipeableDrawer
            open={true}
            onOpen={() => { }}
            onClose={() => { setOpen(false) }}
            PaperProps={dark ? { sx: { backgroundColor: "#000000" } } : { sx: { backgroundColor: "#FFFFFF" } }}
        >
            <AppBar position="static">
                <Toolbar style={dark ? { backgroundColor: "#000000" } : { backgroundColor: "#FFFFFF" }}>
                    <Button
                        style={{ justifyContent: "left" }}
                    >
                        <CloseIcon style={dark ? { color: "#FFFFFF" } : { color: "#000000" }} onClick={() => { setOpen(false) }} />
                    </Button>
                </Toolbar>
            </AppBar>
            <div style={dark ? { justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center", backgroundColor: "#000000" } : { justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center", backgroundColor: "#FFFFFF" }}>
                {pages && pages.map((page) => {
                    return (
                        <div key={page.name} style={{ paddingTop: 5, paddingBottom: 5, width: window.innerWidth }}>
                            <NavLink key={page.name} to={page.link} style={{ textDecoration: "none" }}>
                                <Typography id="navlink" paddingLeft={5} paddingRight={5} color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} variant="h5" style={{ textTransform: "uppercase" }} >
                                    {page.name}
                                </Typography>
                            </NavLink>
                        </div>
                    )
                })}
            </div>
        </SwipeableDrawer>
    )
}