import { Typography } from "@mui/material"
import { useContext } from "react"
import { NavLink } from "react-router-dom"
import { BytSyzContext } from "../context/BytSyzContext"
import { DarkLightModeToggle } from "./DarkLightModeToggle"
import './componentStyles/navbarHover.css'
export const HomeNavbar = ({ pages }) => {
    const { dark, windowNarrow } = useContext(BytSyzContext)
    return (
        <div style={dark ? { backgroundColor: "#000000", flexDirection: "column", textAlign: "center" } : { backgroundColor: "#FFFFFF", flexDirection: "column", textAlign: "center" }} display={"flex"} >
            <div style={windowNarrow ? { justifyContent: "center", display: "flex", flexDirection: "column", textAlign: "center" } : { justifyContent: "center", display: "flex", flexDirection: "row" }}>
                {pages && pages.map((page) => {
                    return (
                        <NavLink key={page.name} to={page.link} style={{ textDecoration: "none" }}>
                            <Typography id="navlink" paddingLeft={5} paddingRight={5} color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} variant="h5" style={{ textTransform: "uppercase" }} >
                                {page.name}
                            </Typography>
                        </NavLink>
                    )
                })}
            </div>
            <div style={{ paddingTop: 10 }}>
                <DarkLightModeToggle />
            </div>
        </div >
    )
}