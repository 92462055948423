import { Typography } from '@mui/material';
import { useContext } from 'react';
import { BytSyzContext } from '../../../context/BytSyzContext';

export const InformationCollectionAndUse = () => {

    const { dark } = useContext(BytSyzContext)

    return (
        <>
            <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                Information Collection and Use
            </Typography>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.
            </Typography>
        </>
    )

}