import { List, ListItem, Typography } from '@mui/material';
import { useContext } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { BytSyzContext } from '../../../context/BytSyzContext';

export const ServiceProviders = () => {
    const { dark } = useContext(BytSyzContext)
    const serviceTerms = [
        "To facilitate our Service;",
        "To provide the Service on our behalf;",
        "To perform Service-related services; or",
        "To assist us in analyzing how our Service is used."
    ]

    return (
        <>
            <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                Service Providers
            </Typography>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                We may employ third-party companies and individuals due to the following reasons:
            </Typography>
            <List>
                {serviceTerms && serviceTerms.map((term, index) => {
                    return (
                        <ListItem key={index} disablePadding>
                            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                                <CircleIcon fontSize='small' style={{ paddingRight: 5 }} />
                                {term}
                            </Typography>
                        </ListItem>
                    )
                })}
            </List>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
            </Typography>
        </>
    )
}