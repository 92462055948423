import { Typography } from '@mui/material';
import { useContext } from 'react';
import { BytSyzContext } from '../../../context/BytSyzContext';

export const Changes = () => {

    const { dark } = useContext(BytSyzContext)

    return (
        <>
            <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                Changes to this Privacy Policy
            </Typography>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
            </Typography>
        </>
    )

}