import { Typography } from '@mui/material';
import { useContext } from 'react';
import { Navbar } from '../../components/Navbar';
import { BytSyzContext } from '../../context/BytSyzContext';
import { InformationCollectionAndUse } from './commonSections/InformationCollectionAndUse';
import { ContactUsStatement } from './commonSections/ContactUsStatement';
import { Changes } from './commonSections/Changes';
import { ChildrenPrivacy } from './commonSections/ChildrenPrivacy';
import { Security } from './commonSections/Security';
import { LinksToOtherSites } from './commonSections/LinksToOtherSites';
import { ServiceProviders } from './commonSections/ServiceProviders';
import { Cookies } from './commonSections/Cookies';
import { LogData } from './commonSections/LogData';
import { ThirdParty } from './commonSections/ThirdParty';
export const QuickNotePrivacyPolicy = () => {
    const { dark } = useContext(BytSyzContext)
    const pages = [
        {
            link: "/",
            name: "Home"
        },
        {
            link: "/Contact_Us",
            name: "Contact Us"
        }
    ]
    return (
        <div style={dark ? { backgroundColor: "#000000", minHeight: "100vh", textAlign: "center" } : { backgroundColor: "#FFFFFF", minHeight: "100vh", textAlign: "center" }}>
            <Navbar pages={pages} />
            <div style={{ padding: 40 }}>
                <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h3" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                    Privacy Policy
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h4" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                    QuickNote
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    BytSyz Limited built the QuickNote app as a free app. This Service is provided by BytSyz Limited at no cost and is intended for use as is.
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                </Typography>
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at QuickNote unless otherwise defined in this Privacy Policy.
                </Typography>
                <InformationCollectionAndUse />
                <ThirdParty />
                <LogData />
                <Cookies />
                <ServiceProviders />
                <Security />
                <LinksToOtherSites />
                <ChildrenPrivacy />
                <Changes />
                <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                    This policy is effective as of 4th December 2022.
                </Typography>
                <ContactUsStatement />
            </div>
        </div>
    )
}