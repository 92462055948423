import { createContext, useEffect, useMemo, useState } from "react";

export const BytSyzContext = createContext({
    dark: true,
    setDark: () => { }
})

export function BytSyzContextProvider({ children }) {

    const [dark, setDark] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false)
    const [firstLoad, setFirstLoad] = useState(true)
    const narrowWindowWidth = 800
    const [windowNarrow, setWindowNarrow] = useState(window.innerWidth < narrowWindowWidth)

    useEffect(() => {
        if (!firstLoad) {
            if ('caches' in window) {
                const data = new Response(dark);
                caches.open('BytSyz').then((cache) => {
                    cache.put('dark', data);
                });
            }
        }
    }, [dark, firstLoad]);

    useEffect(() => {
        if ('caches' in window) {
            caches.open('BytSyz').then((cache) => {
                cache.match('dark').then((matched) => {
                    if (matched !== undefined) {
                        matched.json().then((result) => {
                            setDark(result)
                        })
                    }
                })
            })
        }
        setFirstLoad(false)
    }, [])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowNarrow(window.innerWidth < narrowWindowWidth)
        };
        window.addEventListener('resize', handleWindowResize);
    },
        [setWindowNarrow]
    )

    const bytsyzContext = useMemo(
        () => ({
            dark,
            setDark,
            windowNarrow
        }),
        [
            dark,
            setDark,
            windowNarrow
        ]
    );
    return (
        <BytSyzContext.Provider value={bytsyzContext}>
            {children}
        </BytSyzContext.Provider>
    );
}