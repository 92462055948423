import { Typography } from '@mui/material';
import { useContext } from 'react';
import { BytSyzContext } from '../../../context/BytSyzContext';

export const ContactUsStatement = () => {

    const { dark } = useContext(BytSyzContext)

    return (
        <>
            <Typography style={{ paddingTop: 20, paddingBottom: 40 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} fontWeight={"bold"}>
                Contact Us
            </Typography>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at bytsyzltd@gmail.com.
            </Typography>
        </>
    )

}