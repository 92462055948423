import { List, ListItem, ListItemButton, Typography } from '@mui/material';
import { useContext } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { BytSyzContext } from '../../../context/BytSyzContext';

export const ThirdParty = () => {

    const { dark } = useContext(BytSyzContext)
    const thirdPartyApps = [
        {
            link: "https://www.google.com/policies/privacy/",
            name: "Google Play Services"
        },
        {
            link: "https://support.google.com/admob/answer/6128543?hl=en",
            name: "AdMob"
        },
        {
            link: "https://expo.io/privacy",
            name: "Expo"
        }
    ]

    return (
        <>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                The app does use third-party services that may collect information used to identify you.
            </Typography>
            <Typography style={{ paddingTop: 20, paddingBottom: 20 }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                Links to the privacy policy of third-party service providers used by the app:
            </Typography>
            <List>
                {thirdPartyApps && thirdPartyApps.map((app) => {
                    return (
                        <ListItem key={app.name} disablePadding>
                            <ListItemButton component="a" href={app.link} target={"_blank"}>
                                <Typography style={{ paddingTop: 20, paddingBottom: 20, textDecoration: "underline" }} variant="h5" color={dark ? "#FFFFFF" : "#000000"} fontFamily={"Courier New, Courier, monospace"} >
                                    <CircleIcon fontSize='small' style={{ paddingRight: 5 }} />
                                    {app.name}
                                </Typography>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        </>
    )
}